import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Seo from '../components/seo'
import PageTitle from '../components/PageTitle'
import LegalNav from '../components/LegalNav'
import {  RichText } from 'prismic-reactjs';

const customLink = (type, element, content, children, index) => (

  <Link to={element.data.url} key={index}>
    {content}
  </Link>
)

export const query = graphql`
  query LegalPageQuery($uid: String) {
    allPrismicLegalPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            meta_title {
              text
            }
            title {
              text
            }
            subtitle {
              text
            }
            content {
              spans {
                type
                start
                data {
                  url
                  link_type
                }
                end
            }
              text
              type
            }
          }
        }
      }
    }
  }
`


const LegalPage = ({ data }) => {
  if (!data) return null

  const page = data.allPrismicLegalPage.edges[0].node

  return (
    <Layout>
      <Seo title={page.data.meta_title[0].text} />
      <PageTitle subtitle={page.data.subtitle[0].text} title={page.data.title[0].text} />
      <LegalNav></LegalNav>
      <section className="container container--content page-content rte">
        <div className="container__inner page-content__inner">
          <RichText render={page.data.content} serializeHyperlink={customLink}></RichText>
        </div>
      </section>
    </Layout>
  )


}

export default LegalPage