import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby';

const LegalNav  = ({subtitle, title}) => {

  const legalPages = useStaticQuery(
    graphql`
      query LegalNavLegalPagesQuery($uid: String) {
        allPrismicLegalPage(filter: { uid: { eq: $uid } }) {
          edges {
            node {
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }
      }
    `
  );

  const legalPageItems = legalPages.allPrismicLegalPage.edges

  return(
    <section className="container legal-nav">
      <div className="container__inner legal-nav__inner">
        <nav className="legal-nav__nav">
          <ul className="legal-nav__list">
            {legalPageItems.map((item, i) => {
              return (
                <li className="legal-nav__list-item" key={i}>
                  <Link to={`/${item.node.uid}`} className="legal-nav__list-link" activeClassName="legal-nav__list-link--active">{ item.node.data.title[0].text }</Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </section>
  )
}

export default LegalNav;